<template>
  <div class="sp-video">
    <vue-headful
      v-if="!showOnlyPlayer"
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />
    <div
      :class="{
        'sp-video--container': true,
        'sp-video--container--full': showOnlyPlayer,
      }"
    >
      <div class="sp-video--inner">
        <div class="sp-video--inner--media">
          <div
            v-if="shopPlayerActiveItem && !canPlay(shopPlayerActiveItem)"
          >
            <lock-icon-component></lock-icon-component>
            <img class="subscription-img"
                 :src="shopPlayerActiveItem.backdrop || shopPlayerActiveItem.cover || shopPlayerActiveItem.poster"
                 alt=""
            >
          </div>
          <shop-player
            v-if="shopPlayerActiveItem && canPlay(shopPlayerActiveItem)"
            ref="shopPlayer"
            :class="{'product-popup--active': productPopupDetails}"
            :items="[shopPlayerActiveItem]"
            :active-item="shopPlayerActiveItem"
            :is-loading="false"
            :scroll-navigate="false"
            :rewind="true"
            :blocked-page-scroll="false"
            :shop-controls="{
              upDownToggler: false,
              tabs: false,
              volume: isMobile,
              fullscreen: isMobile,
              productsDrawer: shopPlayerActiveItem && !shopPlayerActiveItem.vod_short
            }"
            :items-products="itemsProducts"
            :items-stats="itemsStats"
            :items-overlay-state="itemsOverlaysState"
            :items-channel-follow-state="itemsChannelsFollowState"
            :chat-config="chatConfig"
            :loop="true"
            :embed-mode="true"
            :background="'purple'"
            :logo-link="logoLink || require('src/assets/logo/logo-vimmi-white.svg')"
            :is-store-name-clickable="true"
            :viewers-limit-reached="viewersLimitReached"
            :get-playback-url="getPlaybackUrl"
            @playback-state="shopPlayerPlaybackStateUpdated"
            @fs-state="shopPlayerFullScreenChanged"
            @open-pip="openFloatPlayer($event)"
            @toggle-follow="toggleFollow($event)"
            @block-scroll="blockPageScroll($event)"
            @unblock-scroll="unBlockPageScroll($event)"
            @chat-user-updated="chatUserUpdated($event)"
            @product-click="onProductClick($event)"
            @close-product="closeProductsDetailsPopup($event)"
            @send-reaction="sendReaction($event)"
            @title-click="openStorePage($event, true, true)"
            @player:stats="shopPlayerStats($event, 'popup')"
            @share="openSharePopup($event)"
            @resize-area="resizeArea($event)"
          >
            <template #products-details-popup>
              <product-details-popup-component
                v-if="productPopupDetails"
                :product="productPopupDetails"
                @close="closeProductsDetailsPopup"
              />
            </template>
          </shop-player>
        </div>
        <div
          v-if="!showOnlyPlayer"
          class="sp-video--inner--content"
        >
          <div>
            <div class="d-flex justify-content-between">
              <div class="sp-video--inner--content--title font-weight-bold">
                {{ video && video.title }}
              </div>
              <div v-if="video" class="d-flex" style="gap: 8px">
                <button
                  class="btn btn-circle-icon btn-favorite "
                  :class="{'active': isFavorite}"
                  @click="toggleFavorite"
                >
                  <i
                    class="fa-heart"
                    :class="{'fas': isFavorite, 'far': !isFavorite}"
                  />
                </button>
                <button
                  class="btn btn-circle-icon btn-share "
                  :class="{'active': isFavorite}"
                  @click="openSharePopup({item:video, link: itemLink})"
                >
                  <i class="far fa-share-alt" />
                </button>
                <!--                <share-btn :item="video" :link="itemLink" />-->
              </div>
            </div>

            <div v-if="!canPlay(video)" class="mt-3 container container-fluid px-0 sp-subscription">
              <div class="row no-gutters">
                <div class="col-md-12">
                  <div class="sp-subscription--subtitle font-weight-bold ">
                    Type
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="sp-subscription--tabs">
                    <div v-if="subsPackages.length"
                         class="sp-subscription--tabs--tab "
                         :class="{'sp-subscription--tabs--tab--active': activePackages === 'subs'}"
                    >
                      <button @click="togglePackages('subs')" class="btn btn-outline-dark btn-default">
                        Subscription
                        <i class="fas fa-question ml-1"></i>
                      </button>
                    </div>
                    <div v-if="buyPackages.length"
                         class="sp-subscription--tabs--tab"
                         :class="{'sp-subscription--tabs--tab--active': activePackages === 'buy'}"
                    >
                      <button @click="togglePackages('buy')" class="btn btn-outline-dark btn-default">
                        Buy
                        <i class="fas fa-question ml-1"></i>
                      </button>
                    </div>
                    <div v-if="rentPackages.length"
                         class="sp-subscription--tabs--tab"
                         :class="{'sp-subscription--tabs--tab--active': activePackages === 'rent'}"
                    >
                      <button @click="togglePackages('rent')" class="btn btn-outline-dark btn-default">
                        Rent
                        <i class="fas fa-question ml-1"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="sp-subscription--subtitle font-weight-bold  mt-3">
                    Subscription
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="sp-subscription--packages">
                    <div v-for="(pack) in packages"
                         class="sp-subscription--package d-flex flex-row "
                         :class="{'sp-subscription--package--active': selectedPackage === pack}"
                    >
                      <div class="col-auto sp-subscription--package--input">
                        <input
                          v-model="selectedPackage"
                          :value="pack"
                          type="radio"
                          name="subscription"
                        >
                      </div>
                      <div class="col mr-auto px-0">
                        <h5 class="sp-subscription--package--title">
                          {{ pack.name }}
                        </h5>
                        <p class="sp-subscription--package--subtitle">{{ pack.description }}</p>
                      </div>
                      <div class="col-auto  sp-subscription--package--price">
                        <span class="sp-subscription--package--price--currency">{{ pack.currency_symbol }}</span>
                        <span class="sp-subscription--package--price--value">{{ pack.priceBase }}</span>
                        <span class="sp-subscription--package--price--value-decimals" v-if="pack.priceDecimals"
                        >{{ pack.priceDecimals }}</span>
                      </div>
                    </div>
                    <button
                      class="btn sp-subscription--btn--buy w-100 text-white"
                      :class="{'sp-subscription--btn--disabled': isSubscribeBtnDisabled || isHandlingPackage}"
                      :disabled="isSubscribeBtnDisabled || isHandlingPackage"
                      @click="subscribe"
                    >
                      BUY NOW
                      <i v-if="isHandlingPackage"
                         class="fas fa-spinner fa-spin mx-2"
                      />
                    </button>

                    <div v-if="subscriptionError" class="my-2 p-2 sp-subscription--error alert-danger">
                      {{ subscriptionError }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="video" class="container container-fluid px-0" style="margin-top: 2.1rem;">
              <div v-if="video.description" class="sp-video--inner--content--subtitle font-weight-bold mb-2 mt-2">
                Description
              </div>
              <p
                v-if="video.description"
                ref="description"
                class="sp-video--inner--content--desc"
                :class="{'sp-video--inner--content--desc--full': isShowMoreDesc}"
              >
                <span v-html="video.description"></span>
                <button
                  v-if="isShowMoreBtn"
                  class="sp-video--inner--content--desc--more"
                  @click="toggleDesc()"
                >{{ isShowMoreDesc ? 'Less' : 'More' }}
                </button>
              </p>

              <div class="sp-video--inner--content--subtitle font-weight-bold mb-2 ">
                Details
              </div>
              <div class="sp-video--inner--content--details d-flex flex-column">
                <!--                <div class="d-flex sp-video&#45;&#45;inner&#45;&#45;content&#45;&#45;details&#45;&#45;item">
                                  <div class="sp-video&#45;&#45;inner&#45;&#45;content&#45;&#45;details&#45;&#45;title">Lecturers</div>
                                  <div>{{ video.lectures }}</div>
                                </div>-->
                <div class="d-flex sp-video--inner--content--details--item">
                  <div class="sp-video--inner--content--details--title">Duration</div>
                  <div>{{ video.duration | duration }}</div>
                </div>
                <!--                <div class="d-flex sp-video&#45;&#45;inner&#45;&#45;content&#45;&#45;details&#45;&#45;item">
                                  <div class="sp-video&#45;&#45;inner&#45;&#45;content&#45;&#45;details&#45;&#45;title">Quality</div>
                                  <div>4k, HD</div>
                                </div>-->
                <!--                <div class="d-flex sp-video&#45;&#45;inner&#45;&#45;content&#45;&#45;details&#45;&#45;item">
                                  <div class="sp-video&#45;&#45;inner&#45;&#45;content&#45;&#45;details&#45;&#45;title">Additional content</div>
                                  <div><a class="sp-video&#45;&#45;inner&#45;&#45;content&#45;&#45;details&#45;&#45;download" download="test">Download</a></div>
                                </div>-->

              </div>
            </div>

          </div>
        </div>
      </div>
      <div v-if="!showOnlyPlayer && related" class="sp-video--rellate">
        <segment-carousel
          :area="related"
          ordered
        />
      </div>
    </div>
  </div>

</template>

<script>
  import { mapActions } from 'vuex';
  import device from '@/service/device-service';
  import VideoApi from './api';
  import normaliseMetaTags from '@/utils/normaliseMetaTags';
  import ProductDetailsPopupComponent from '@/shared/components/shop-player/product-details-popup.component.vue';
  import ShopPlayerMixin from '@/shared/mixins/shop-player.mixin';
  import { AuthVariableMixin, FavoriteMixin, ScreenReadyMixin } from '@/plugins/mixin';
  import FloatPlayerMixin from '@/shared/mixins/float-player.mixin';
  import ShareBtn from '@/components/share-btn/share-btn.vue';
  import LockIconComponent from '@/shared/components/lock-icon.vue';
  import SegmentCarousel from '@/components/segment-carousel/segment-carousel.vue';
  import getPrid from '@/shared/helpers/get-prid';


  export default {
    name: 'VideoContainer',
    mixins: [ScreenReadyMixin, FloatPlayerMixin, ShopPlayerMixin, FavoriteMixin, AuthVariableMixin],
    components: { SegmentCarousel, LockIconComponent, ShareBtn, ProductDetailsPopupComponent },
    props: {
      showOnlyPlayer: {
        type: Boolean,
        default: false,
      },
      videoIdProp: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        metadata: {},
        video: null,
        shopPlayerActiveItem: null,
        mediaId: true,
        itype: true,
        rentPackages: [],
        subsPackages: [],
        buyPackages: [],
        selectedPackage: null,
        isShowMoreBtn: false,
        isShowMoreDesc: false,
        activePackages: 'subs',
        isHandlingPackage: false,
        subscriptionError: '',
        related: null,
      };
    },
    computed: {
      isMobile() {
        return device.isAnyMobile() || device.isMobileSize();
      },
      videoId() {
        return this.videoIdProp || this.$route.params.id;
      },
      isSubscribeBtnDisabled() {
        return !this.selectedPackage || !this.packages.length;
      },
      itemLink() {
        return location.href;
      },
      packages() {
        if (this.activePackages === 'subs') {
          return this.subsPackages;
        } else if (this.activePackages === 'rent') {
          return this.rentPackages;
        } else if (this.activePackages === 'buy') {
          return this.buyPackages;
        }
        // return this.activePackages === 'subs' ? this.subsPackages : this.rentPackages;
      },
    },
    watch: {
      selectedPackage() {
        if (this.selectedPackage?.prdid && (this.related?.id !== this.selectedPackage.prdid)) {
          this.related = null;
          this.$nextTick(() => {
            this.related = {
              id: this.selectedPackage.prdid,
              itype: 'section_shoprz',
              n_col: 3,
              aspect_ratio: '16x9',
              dtype: 'carousel',
              background_color: '#FFFFFF',
              item_image_shape: 'horizontal',
              item_title_position: 'bottom',
              align: 'left',
              size: 'small',
              creator: this.video.creator || this.storefront,
              title: 'Related Videos',
              link: `/filter/item_mov_vod_shoprz/?filters=provider_id:${getPrid()}@products__in:${this.selectedPackage.prdid}&count=20&start=0&sort=-created_at`,
            };
          });
        }
      },
    },
    async mounted() {

      await this.fetchVideo();
      if (!this.canPlay(this.video)) {
        if (!this.packages || !this.packages.length) {
          await this.fetchPackages();
        }
      }

      this.$nextTick(() => {
        this.calcIsShowMoreBtn();
      });
      this.metadata = normaliseMetaTags(this.video);
    },

    beforeDestroy() {
    },

    methods: {
      ...mapActions({
        setPlaybackState: 'popupPlayer/setPlaybackState',
      }),
      async onChangedUserState() {
        console.log('onChangedUserState video');
        if (this.floatItem && !this.canPlay(this.floatItem)) {
          this.hideFloatPlayer();
        }
        if (this.video) {
          if (!this.canPlay(this.video)) {

            setTimeout(() => {
              this.shopPlayerActiveItem = { ...this.video, uniq_id: this.video.id };
            }, 200);
            if (!this.packages || !this.packages.length) {
              await this.fetchPackages();
            }
          } else {
            setTimeout(() => {
              this.shopPlayerActiveItem = { ...this.video, uniq_id: this.video.id };
            }, 200);
            this.subsPackages = [];
            this.rentPackages = [];
          }
        }
      },
      calcIsShowMoreBtn() {
        this.$nextTick(() => {
          this.isShowMoreBtn = this.video?.description && this.$refs.description && this.$refs.description?.scrollHeight - 2 > this.$refs.description?.clientHeight;
        });
      },
      toggleDesc() {
        // this.$refs.description.style.maxHeight = 'none';
        // this.$refs.description.style.webkitLineClamp = '100';

        this.isShowMoreDesc = !this.isShowMoreDesc;
        // this.$nextTick(() => {
        //   this.calcIsShowMoreBtn();
        // });
      },

      /**
       * @method
       * @desc used in the float-player-mixin
       * @return {Vue & ShopPlayer}
       * */
      getShopPlayerRef() {
        return this.$refs.shopPlayer;
      },
      /**
       * @method
       * @desc used in the float-player-mixin
       * @return {Object}
       * */
      getActiveItem() {
        return this.shopPlayerActiveItem;
      },
      async fetchVideo() {
        try {
          const video = await VideoApi.getItem(this.videoId);
          if (!video) {
            this.$plError.setError({
              text: 'Video not found',
              title: 'Not found',
              code: 404,
            });

            throw new Error('Video not found');
          }

          this.video = video;
          this.mediaId = video.id;
          this.itype = video.itype;

          this.shopPlayerActiveItem = { ...video, uniq_id: video.id };
        } catch (err) {
          this.$router.replace({ path: '/error' });
        }
      },
      async fetchPackages() {
        try {
          this.isHandlingPackage = true;
          let packages = await VideoApi.getPackages(this.video.id);
          packages.map((pack) => {
            if (pack.price % 1 !== 0) {
              const parts = (pack.price.toFixed(2)).split('.');
              pack.priceBase = parts[0];
              pack.priceDecimals = parts[1];
            } else {
              pack.priceBase = pack.price;
            }
            return pack;
          }).forEach((pack) => {
            if (pack.pricing_method === 'RENT') {
              this.rentPackages.push(pack);
            } else if (pack.pricing_method === 'SUBS') {
              this.subsPackages.push(pack);
            } else if (pack.pricing_method === 'BUY') {
              this.buyPackages.push(pack);
            }
          });
          if (this.subsPackages.length) {
            this.activePackages = 'subs';
            this.selectedPackage = this.subsPackages[0];
          } else if (this.rentPackages.length) {
            this.activePackages = 'rent';
            this.selectedPackage = this.rentPackages[0];
          } else if (this.buyPackages.length) {
            this.activePackages = 'buy';
            this.selectedPackage = this.buyPackages[0];
          }
        } catch (err) {
          this.subsPackages = [];
          this.rentPackages = [];
          this.buyPackages = [];
        }
        this.isHandlingPackage = false;
      },

      async subscribe() {
        if (!this.isSubscribeBtnDisabled && !this.isFullAuth) {
          this.$bus.$emit('toggleAuthPopup', { component: 'login', force: true });
          return;
        }
        this.isHandlingPackage = true;


        try {
          const query = this.selectedPackage.billing_iframe.slice(1);
          const billInfo = await VideoApi.getBillingPriceId(query);
          const session = await VideoApi.generateCheckoutSession(query, {
            'priceId': billInfo.basicPrice,
            'successUrl': `${location.href}/?result=success`,
            'cancelUrl': `${location.href}/?result=cancel`,
          });
          if (session.url) {
            location.href = session.url;
          } else {
            throw new Error(session.error || 'url not found');
          }
        } catch (e) {
          console.dir(e);
          this.subscriptionError = 'Something went wrong, please try again later';

          setTimeout(() => {
            this.subscriptionError = '';
          }, 15000);
        }
        this.isHandlingPackage = false;
      },
      togglePackages(type) {
        this.activePackages = type;
        this.selectedPackage = this[type + 'Packages'][0];
      },

      shopPlayerPlaybackStateUpdated({ itemId, state }) {
        if (itemId === this.shopPlayerActiveItem?.id) {
          this.shopPlayerPlaybackState = state;
          this.setPlaybackState(state);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .shop-player-container {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .sp-video--inner--media ::v-deep .lock-icon-component {
    top: calc(50% - 60px);
    right: calc(50% - 60px);
    width: 120px;
    height: 120px;
    z-index: 1;
    border-radius: 10px;
    background: rgb(0 0 0 / 50%);

    i {
      font-size: 2.9rem;
    }
  }

  ::v-deep .segment {
    padding: 0 !important;
  }

  .btn, ::v-deep .share-block-activator {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    border: 1px solid #E2E6EE;
    background-color: transparent !important;

    .fa-share-alt {
      color: black;
    }
  }

  .sp {

    &-subscription {
      &--subtitle {
        color: black;
        font-size: 15px;
        line-height: 25px;
        margin-bottom: 1px;
      }

      &--tabs {
        display: flex;
        gap: 4px;

        &--tab {
          .btn {
            padding: 0 15px;
            border-radius: 4px;
            height: 44px;
            font-size: 15px;
            line-height: 16px;
            font-weight: 500;

            &:hover {
              color: #0000009c;
            }

            .fa-question {
              width: 14px;
              height: 14px;
              border-radius: 7px;
              border: 1px solid #B6C0D3;
              color: #B6C0D3;
              font-size: 8px;
              padding-top: 2px;
            }
          }

          &--active {
            border-radius: 4px;
            border: 1px solid #343434;
          }
        }
      }


      &--packages {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }

      &--package {
        padding: 12px 0;
        display: flex;
        max-height: 70px;
        justify-content: space-between;
        border-radius: 5px;
        border: 1px solid #E2E6EE;

        &--input {
          padding-left: 0.8rem;
          padding-right: 0.6rem;
        }

        &--title {
          font-size: 16px;
          //line-height: 40px;
          font-weight: 500;
          margin-bottom: 3px;
          margin-top: 2px;

          line-height: 18px;
          max-height: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        &--subtitle {
          font-size: 15px;
          color: #B6C0D3;
          font-weight: 400;
          margin-bottom: 0;
          line-height: 16px;
          max-height: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        &--price {
          font-size: 26px;
          font-weight: 500;
          display: flex;
          flex-direction: row;
          align-self: center;
          //span{
          //  line-height: 28px;
          //}

          &--currency {
            font-size: 14px;
            //margin-top: -4px;
          }

          &--value {
            line-height: 28px;
          }

          &--value-decimals {
            font-size: 14px;
            //margin-top: -4px;
          }
        }

        &--active {
          border: 1px solid #21A66E;
          border-radius: 5px;
        }
      }

      &--btn {
        &--buy {
          border-radius: 5px;
          background-color: #21A66E !important;
          color: white !important;
          height: 50px;
          padding: 12px 24px;
          margin-top: 12px !important;
          align-items: center;
        }

        &--disabled {
          background-color: #6c757d !important;
          cursor: not-allowed;
          opacity: 0.4;
        }
      }
    }

    &-video {
      &--container {
        &--full {
          max-width: 100% !important;
          padding: 0 !important;

          .sp-video--inner {
            display: block !important;
            padding-bottom: 0 !important;
          }
        }

        max-width: 768px;
        padding: 0px 15px;
        margin: 0 auto;

        @include lg() {
          max-width: 1100px;
        }

        @media screen and (min-width: 1460px) {
          max-width: 1650px;
        }
      }

      &--inner {
        display: grid;
        column-gap: 12px;
        row-gap: 12px;
        grid-template-columns: 1fr;
        padding-bottom: 40px;

        @include lg() {
          grid-template-columns: minmax(320px, 1fr) minmax(320px, 560px);
          column-gap: 40px;
          row-gap: 40px;
        }

        &--media {
          overflow: hidden;
          border-radius: 10px;


          position: relative;
          width: 100%;
          padding-top: 56.25%; /* 9/16 = 0.5625 */
          overflow: hidden;


          @include lg() {
            position: sticky;
            align-self: start;
          }

          .shop-player, .subscription-img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }

          .subscription-img {
            width: 100%;
            object-fit: contain;
          }
        }

        &--content {
          &--title {
            font-size: 1.35rem;
          }

          &--subtitle {
            font-size: 1.10rem;
          }

          &--desc {
            font-size: 0.95rem;
            line-height: 1rem;
            max-height: 3rem; /* 3 lines */
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;

            padding-right: 45px;
            position: relative;

            &--full {
              -webkit-line-clamp: 200;
              max-height: unset;
            }

            &--more {
              color: #4B89E5;
              font-size: 15px;
              position: absolute;
              bottom: 0;
              right: 0;
            }

            a {
              color: #4B89E5;
            }
          }

          &--details {
            gap: 12px;

            &--item {
              border-bottom: 1px solid #EEF1F5;
              padding-bottom: 6px;
            }

            &--title {
              font-size: 0.95rem;
              //font-weight: 600;
              //margin-right: 10px;
              width: 40%;
            }

            &--download {
              color: #4B89E5;
            }
          }
        }
      }
    }
  }
</style>