import { amsClient } from '@/service/ams';

export default {
  async getItem(id) {
    const response = await amsClient.callAms(`/get_item/${id}`, { cache: false });
    return response.head;
  },
  async getPackages(id) {
    const response = await amsClient.callAms(`/accounts/offer/${id}?d=${Date.now() / 1000}`, { cache: false });
    return response.info;
  },
  async getBillingPriceId(query) {
    const response = await amsClient.callAms(`/shop/checkout/stripe/setup/?${query}`, { cache: false });
    return response.info;
  },
  async generateCheckoutSession(query, body) {
    return await amsClient.callAms(`/shop/checkout/stripe/create-checkout-session/?${query}`, {
      cache: false,
      method: 'POST',
      body: JSON.stringify(body),
    });
  },
};
